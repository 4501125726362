import React, { useEffect, useState } from "react";
import Box from "common/components/Box";
import BlogDetailsWrapper from "./blogDetails.style";
import Container from "common/components/UI/Container";
import Merchant from "./merchant";
import Driver from "./driver";
import Diner from "./diner";

export default function BlogDetails() {
    const [blog, setBlog] = useState(null);

    useEffect(() => {
        const pathname = window.location.pathname;
        const segments = pathname.split("/").filter(Boolean);
        setBlog(segments[segments.length - 1]);
    }, []);

    return (
        <BlogDetailsWrapper>
            <Container>
                <Box className="blog-details">
                    {blog === "merchant" ? (
                        <Merchant />
                    ) : blog === "driver" ? (
                        <Driver />
                    ) : (
                        <Diner />
                    )}
                </Box>
            </Container>
        </BlogDetailsWrapper>
    );
}
