import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const BlogDetailsWrapper = styled.section`
    padding-top: 120px;
    padding-bottom: 100px;
    @media (max-width: 1550px) {
        padding-bottom: 70px;
    }
    .blog-details {
        width: 60%;
        @media (max-width: 768px) {
            width: 100%;
        }
        figure {
            margin: 0;
            img {
                border-radius: 5px;
                width: 100%;
                height: 500px;
                object-fit: cover;
            }
        }
        .blog-tags {
            margin-top: 20px;
            margin-bottom: 20px;
            .tag {
                padding: 6px 16px;
                background: #ac1f26;
                color: #fff;
                border-radius: 10px;
                margin-right: 10px;
            }
        }
        p {
            text-align: justify;
        }
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        @media (min-width: 1551px) {
            max-width: 1430px;
        }
    }
    .content {
        flex: 0 0 46%;
        padding-top: 130px;
        align-items: center;
        @media (max-width: 1550px) {
            padding-top: 20%;
        }
        @media (max-width: 1199px) {
            padding-top: 40px;
        }
        @media (max-width: 768px) {
            padding-top: 0px;
            flex: auto;
            display: block;
            width: 100%;
        }
        h2 {
            font-weight: bold;
            font-size: 58px;
            line-height: 1.29;
            letter-spacing: -2px;
            color: #081c27;
            margin-bottom: 15px;
            @media (max-width: 1550px) {
                font-size: 48px;
                max-width: 550px;
            }
            @media (max-width: 1199px) {
                font-size: 38px;
            }
            @media (max-width: 768px) {
                max-width: 450px;
                margin-left: auto;
                margin-right: auto;
            }
            @media (max-width: 480px) {
                font-size: 32px;
                max-width: 360px;
            }
            @media (max-width: 425px) {
                font-size: 28px;
                max-width: 310px;
            }
        }
        .paragraph {
            font-size: 18px;
            font-family: "Work Sans";
            line-height: 2.11;
            letter-spacing: -0.3px;
            color: #00334e;
            margin-bottom: 0;
            opacity: 0.8;
            @media (max-width: 1550px) {
                font-size: 16px;
            }
            @media (max-width: 1199px) {
                max-width: 400px;
            }
            @media (max-width: 768px) {
                margin-left: auto;
                margin-right: auto;
            }
            @media (max-width: 480px) {
                font-size: 15px;
                max-width: 350px;
            }
        }
        .tagLine {
            font-size: 15px;
            margin-bottom: 0;
            color: #33596a;
            font-family: "DM Sans";
            @media (max-width: 480px) {
                font-size: 14px;
            }
        }
    }
`;

export default BlogDetailsWrapper;
