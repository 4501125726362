import React from "react";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Fade from "react-reveal/Fade";
import driver from "../../../common/assets/image/foodDelivery/driver.png";
const tags = ["news", "quickeat"];

export default function Driver() {
	return (
		<article>
			<Fade bottom cascade>
				<figure>
					<Image src={driver} alt={"driver"} />
					{/* <div className="blog-tags">
                        {tags?.map((t) => (
                            <span className="tag">{t}</span>
                        ))}
                    </div> */}
				</figure>
			</Fade>
			<Heading content="GoDriver Blog: Say Goodbye to the Old 'Squeeze Play' Tactics of Traditional Food Delivery Apps" />
			<p>
				With GoDriver and its AI sidekick <strong>BoostBot</strong>, drivers enjoy fair pay, respect, and smarter earning opportunities, creating a
				thriving and supportive community for all.
			</p>

			<h2>Join the GoDriver Revolution: Fair Pay and BoostBot Magic</h2>
			<p>
				Ever feel like food delivery apps are out to get you? You’re not alone. Influencers on YouTube are constantly trying to crack the code of
				these “squeeze play” apps designed to squeeze every bit of profit from restaurant owners, diners, and drivers. It’s a relentless, AI-powered
				system, always finding new ways to take more. And the drivers? They’re often left struggling to make ends meet.
			</p>
			<p>
				But what if we told you there’s a hero in town? Meet <strong>GoDriver</strong>, the champion of delivery drivers everywhere, equipped with an
				AI sidekick called <strong>BoostBot</strong>, designed to level the playing field.
			</p>

			<h3>The Squeeze Play Problem</h3>
			<p>
				Traditional food delivery apps send orders to the first driver in the queue, who then has to either accept or decline the offer. It requires
				skill and knowledge to master factors like the time of day, distance, time duration, earnings per mile, and total earnings. It’s like trying
				to outsmart a sneaky AI that’s always one step ahead. But what if you had an AI that worked for you, protecting your earnings per mile
				automatically?
			</p>

			<h3>BoostBot: The Game Changer</h3>
			<p>
				<strong>BoostBot</strong> is here to change the game. Instead of simply assigning orders based on who’s been waiting the longest, BoostBot
				determines who is earning the least while drivers are waiting and aims to find an offer above the average to lift everyone up. Let’s break it
				down:
			</p>
			<ul>
				<li>Imagine you’re earning about $15 per hour, but the average is $22.50 per hour.</li>
				<li>BoostBot works to create higher-income opportunities for you.</li>
				<li>It might send you a single order worth over $10 or combine orders to exceed $15 in earnings.</li>
			</ul>

			<h4>Comparing the Squeeze Play vs. BoostBot</h4>
			<h4>The Squeeze Play:</h4>
			<ol>
				<li>
					<strong>Pros:</strong>
				</li>
				<ul>
					<li>Potential to earn big if you master the system.</li>
					<li>Encourages strategic thinking and planning.</li>
				</ul>
				<li>
					<strong>Cons:</strong>
				</li>
				<ul>
					<li>Requires constant vigilance and skill.</li>
					<li>Can leave drivers struggling if they don't know the tricks.</li>
					<li>Often leaves the weakest link behind.</li>
				</ul>
			</ol>
			<h4>BoostBot:</h4>
			<ol>
				<li>
					<strong>Pros:</strong>
				</li>
				<ul>
					<li>Levels the playing field for all drivers.</li>
					<li>Automatically finds the best earning opportunities for those making the least.</li>
					<li>Ensures everyone has a fair shot at earning well.</li>
				</ul>
				<li>
					<strong>Cons:</strong>
				</li>
				<ul>
					<li>Requires a bit of patience (needs 2 hours of consecutive service to kick in).</li>
				</ul>
			</ol>

			<h3>The Feng Shui of Food Delivery</h3>
			<p>
				Think of BoostBot as the feng shui of food delivery – it’s not about outsmarting the system, but about creating harmony and balance. When you
				work with BoostBot, you allow the process to flow, and after 2 hours of consecutive delivery service, BoostBot starts working harder for you.
				It makes sure everyone earns good money and gets fair opportunities. It's about accepting the process and letting BoostBot create the best
				opportunities for you.
			</p>

			<h3>How BoostBot Works</h3>
			<p>
				BoostBot uses a smart algorithm to help drivers earn more by favoring those earning the least while waiting for new delivery orders. Here’s
				how it works, step-by-step:
			</p>
			<ol>
				<li>
					<strong>Current Order Value:</strong> $20
				</li>
				<li>
					<strong>Tip Percentage:</strong> 15%
				</li>
				<li>
					<strong>GoBig Pays:</strong> $3 per delivery
				</li>
				<li>
					<strong>Deliveries per Hour:</strong> 2.5
				</li>
			</ol>

			<h4>Example Calculations</h4>
			<ol>
				<li>
					<strong>Tip Earnings per Delivery:</strong>
				</li>
				<p>Tip = 15% of $20 = $3</p>
				<li>
					<strong>Total Earnings per Delivery:</strong>
				</li>
				<p>Total Earnings = Tip + GoBig Pay = $3 + $3 = $6</p>
				<li>
					<strong>Hourly Earnings:</strong>
				</li>
				<p>Hourly Earnings = Total Earnings per Delivery * Deliveries per Hour = $6 * 2.5 = $15</p>
			</ol>

			<h3>Real-Life Scenario</h3>
			<p>Imagine you’re driving and earning about $15 an hour. BoostBot identifies you as the driver earning the least and swings into action:</p>
			<ol>
				<li>
					<strong>Single Orders:</strong> BoostBot sends you a higher-value single order worth over $10. Completing 2.5 of these deliveries per hour
					would make your earnings $25 per hour.
				</li>
				<li>
					<strong>Batch Orders:</strong> BoostBot combines two orders to exceed $15, allowing you to earn $30 per hour by completing 2 deliveries.
				</li>
				<li>
					<strong>Stack Orders:</strong> While waiting for a food pickup, BoostBot might add another order from the same restaurant. This could
					maximize your earnings to $30 per hour with 2 deliveries.
				</li>
			</ol>

			<h3>The GoDriver Advantage: Always Supporting the Team</h3>
			<p>
				Unlike other apps, GoBig’s BoostBot algorithm is all about supporting the weakest link on the team. A winning team needs everyone to be
				earning and getting rewards.
			</p>

			<h4>How to Maximize BoostBot Benefits</h4>
			<p>
				Since the BoostBot algorithm focuses on boosting your income, you should plan on working over 2 hours to really gain from it. Remember,
				waiting for opportunities and passing on offers assumes you want to outsmart BoostBot, and it will not work for you. You have to trust the
				process and allow the algorithm to work harder for you, and it does take time. That’s why we need 2 hours.
			</p>

			<h2>Join the GoDriver Revolution</h2>
			<p>
				Tired of the old squeeze play tactics? Say hello to GoDriver, where fair pay and respect are the standard, not the exception. With GoDriver
				and BoostBot by your side, we’re making food delivery fair, fun, and fabulous again.
			</p>
			<p>Spread the word, rally your fellow drivers, and let’s build a thriving community together.</p>
		</article>
	);
}
