import React from "react";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Fade from "react-reveal/Fade";
import merchant from "../../../common/assets/image/foodDelivery/merchant.png";
const tags = ["news", "quickeat"];

export default function Merchant() {
	return (
		<article>
			<header>
				<Fade bottom cascade>
					<figure>
						<Image src={merchant} alt={"merchant"} />
						{/* <div className="blog-tags">
                            {tags?.map((t) => (
                                <span className="tag">{t}</span>
                            ))}
                        </div> */}
					</figure>
				</Fade>
				<Heading content="Restaurant Owner Blog: GoBig’s Pay-It-Forward Model" />
				<p>
					<strong>GoBig’s Pay-It-Forward model revolutionizes the food delivery industry</strong> by reinvesting commissions into diner discounts and
					empowering restaurants to control their financial strategies. This community-centric approach fosters goodwill, strengthens local
					connections, and offers a sustainable, humor-filled way for restaurants to thrive and diners to save.
				</p>
				<h2>GoBig or GoHome: The Future of Food Delivery</h2>
			</header>

			<section>
				<p>
					In the crazy world of food delivery apps, traditional platforms—let’s call them the
					<strong>"squeeze play" apps</strong>—are like that annoying sibling who always takes the last slice of pizza. They grab hefty commissions
					from restaurants and slap extra fees on diners. It’s a nightmare for your wallet and your favorite local eatery.
				</p>
				<p>
					<strong>But fear not!</strong> Enter GoBig’s Pay-It-Forward model, the superhero of food delivery, flipping the script with a focus on
					community and kindness over pure profit.
				</p>
			</section>

			<section>
				<h3>A New Currency of Kindness</h3>
				<p>
					Imagine a world where your money doesn’t just disappear into corporate coffers but transforms into a <strong>currency of goodwill</strong>.
					That’s GoBig’s Pay-It-Forward model—reinvesting commissions into diner discounts.
					<strong>The more a restaurant gives, the more diners save.</strong> Each transaction becomes an act of communal support, creating a ripple
					effect of positivity.
				</p>
				<p>
					Now, let’s talk about the <strong>0% commission and discount scenario</strong>. Here’s the deal: GoBig charges a small $4 fee to incentivize
					restaurants to show some love back. It’s a gentle nudge to encourage restaurants to offer commissions that reinvest into diner discounts. To
					be sustainable, GoBig has to stand out with its GoHome membership value, or else the squeeze play apps will keep taking from both the
					restaurant and diner.
				</p>
				<p>The Pay-It-Forward model is all about creating value for diners, making the GoHome membership worthwhile.</p>
			</section>

			<section>
				<h3>Crafting Your Perfect Discount Strategy with Digital Cards</h3>
				<p>
					Imagine you’re a restaurant owner in the fast-paced food delivery game. You’re juggling high commissions, fees, and hangry customers.
					<strong>GoBig’s digital discount cards are here to save the day</strong>, offering three levels of strategy to fit your business needs and
					goals.
				</p>

				<h4>Level 1: The Default Route</h4>
				<p>
					Start with the basics. If you do nothing, GoBig sets you up with a <strong>30% commission</strong> and a <strong>30% diner discount</strong>
					. On a $100 order, you pay $30, and your diner saves $30. Easy peasy.
				</p>

				<h4>Level 2: Custom Digital Cards—Your Strategic Playground</h4>
				<p>
					Now, let’s get creative. Tailor your commission and discount strategy to your exact needs. Choose from cards offering discounts from{" "}
					<strong>0% to 30%</strong>. For example, selecting <strong>DC10</strong> means a 10% commission, a $3 fixed fee, and a 10% diner discount.
					<strong>You’re the puppet master</strong>, pulling strings to optimize your strategy.
				</p>

				<h4>Level 3: BOOST Cards—High-Octane Promotions</h4>
				<p>
					Need a little extra oomph during slow periods? BOOST Cards are like espresso shots for your business. Offering{" "}
					<strong>35% to 50% commissions</strong> that translate directly into diner discounts, these cards create traffic surges when you need them
					most.
				</p>
				<p>
					For example, the <strong>RB40 card</strong> offers a 40% commission and a 40% discount. Restaurants can control its display with a countdown
					timer or a limited quantity available. The scarcity creates urgency, giving diners just 3 minutes to complete their cart.
				</p>
			</section>

			<section>
				<h3>How to Set Up Digital Cards</h3>
				<p>
					Alright, restauranteurs, buckle up! GoBig’s digital cards are about to make your life a whole lot easier—and funnier! Here’s your game plan:
				</p>
				<ol>
					<li>
						<strong>Accessing the Storefront Tablet</strong>: Get your very own storefront tablet, your restaurant’s command center. Captain Kirk, eat
						your heart out!
					</li>
					<li>
						<strong>Setting Up Hours of Operation</strong>: Default settings are 30% commission and 30% diner discount. Think of it as cruise control
						for your restaurant.
					</li>
					<li>
						<strong>Selecting Commission Amounts and Start/End Times</strong>: Customize your strategy with Level 2: Custom Digital Cards.
					</li>
					<li>
						<strong>Understanding Fixed Fees and Time Limits</strong>: Balance commission payments and diner discounts.
					</li>
					<li>
						<strong>Setting Up Revenue Boosters</strong>: Use Level 3: BOOST Cards for temporary traffic surges.
					</li>
				</ol>
			</section>

			<section>
				<h3>Future of Restaurant Management</h3>
				<p>
					With GoBig’s dynamic digital card setup and <strong>AI AutoPilot</strong>, restaurant management will become smarter and easier. AI will
					fine-tune your strategy, suggesting the best templates tailored to your needs.
				</p>
			</section>

			<section>
				<h3>Join the Movement: A Community-Centric Revolution</h3>
				<p>
					GoBig’s Pay-It-Forward model transforms profit-driven practices into a <strong>community-centric approach</strong>. By reinvesting
					commissions into diner discounts and empowering restaurants to control their financial strategies, we build deeper connections and mutual
					support within local communities.
				</p>
				<p>
					Feeling inspired? Join the revolution with GoBig, where <strong>community, convenience, and connection</strong> reign supreme.
					<strong>GoBig or go home</strong>—literally!
				</p>
			</section>
		</article>
	);
}
