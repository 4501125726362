import React from "react";
import Heading from "common/components/Heading";
import Image from "common/components/Image";
import Fade from "react-reveal/Fade";
import diner from "../../../common/assets/image/foodDelivery/diner.png";
const tags = ["news", "quickeat"];

export default function Diner() {
	return (
		<article>
			<Fade bottom cascade>
				<figure>
					<Image src={diner} alt={"diner"} />
					{/* <div className="blog-tags">
                        {tags?.map((t) => (
                            <span className="tag">{t}</span>
                        ))}
                    </div> */}
				</figure>
			</Fade>
			<Heading content="Customer Blog: GoBig’s Pay-It-Forward Model" />
			<p>
				GoBig’s Pay-It-Forward model revolutionizes the traditional food delivery business by reinvesting commissions into diner discounts, empowering
				both restaurants and diners while fostering community goodwill. This new approach offers personalized, engaging experiences through its AI
				assistant, <strong>Foodie</strong>, creating a future where technology and human connection thrive together.
			</p>

			<h2>GoBig: The Knight in Shining Armor for Food Delivery</h2>
			<p>
				Alright, let’s get real. Picture yourself as a busy parent, juggling work, kids, and the daily chaos of life. You've got one eye on the clock,
				knowing dinner time is creeping up, and the thought of cooking feels more exhausting than a marathon. Enter food delivery—your lifeline. But
				wait, traditional delivery apps, let’s call them the <strong>"squeeze play"</strong> apps, are like money-grubbing goblins, snatching hefty
				commissions from your favorite restaurants and slapping extra fees on you. It’s a lose-lose situation. Your local pizzeria is left frazzled,
				and you’re paying way more than you should.
			</p>
			<p>
				But then along comes <strong>GoBig</strong>, the knight in shining armor, flipping the script like a perfectly timed punchline. Here’s the
				kicker: GoBig lets restaurants decide how much commission they want to offer and then reinvests that commission into diner discounts. Imagine
				your favorite pizza joint actually having a say in how much they’re charged, and then turning that into discounts for you! This means
				restaurants can finally breathe a sigh of relief, and diners like you get to enjoy more savings. It’s like finding a hidden stash of chocolate
				in the pantry—completely unexpected and oh so sweet.
			</p>

			<h3>GoHome Membership Options</h3>
			<ul>
				<li>
					<strong>Freemium:</strong> Weekly: $0, Monthly: $0, "Up to" Savings: $20.00
				</li>
				<li>
					<strong>Save $0:</strong> Weekly: $1.49, Monthly: $5.96, "Up to" Savings: $0
				</li>
				<li>
					<strong>Save $5:</strong> Weekly: $2.49, Monthly: $9.96, "Up to" Savings: $5.00
				</li>
				<li>
					<strong>Save $10:</strong> Weekly: $3.49, Monthly: $13.96, "Up to" Savings: $10.00
				</li>
				<li>
					<strong>Save $20:</strong> Weekly: $4.49, Monthly: $17.96, "Up to" Savings: $20.00
				</li>
				<li>
					<strong>Save $40:</strong> Weekly: $8.49, Monthly: $33.96, "Up to" Savings: $40.00
				</li>
				<li>
					<strong>Save $100:</strong> Weekly: $17.49, Monthly: $69.96, "Up to" Savings: $100.00
				</li>
			</ul>

			<h3>The Johnsons' Adventure with GoBig</h3>
			<p>
				Let’s follow the Johnsons, a modern family, as they discover GoBig. Jane Johnson, ever the savvy shopper, downloads the GoBig app and is
				greeted by an AI assistant, <strong>“Foodie.”</strong> Foodie is quirky and has a knack for cracking jokes that are spot-on with Jane’s sense
				of humor. It’s like having a stand-up comedian guide her through dinner choices.
			</p>
			<p>
				Jane starts with the <strong>GoHome freemium membership</strong>, which offers up to $20 in discounts and free delivery for 30 days. The
				Johnsons, being the fun-loving family they are, order food about one and a half times per week. By the end of the month, they’ve saved up to{" "}
				<strong>$115</strong>! Jane is over the moon. “This is better than finding out the in-laws are canceling their visit!” she exclaims.
			</p>

			<h3>When to Upgrade: More Savings, More Fun</h3>
			<p>
				As the freemium period ends, Jane gets a friendly nudge from Foodie. “Time to keep the savings going, Jane! Check out our membership options.”
				She explores the plans and decides on the <strong>“Save$40” membership</strong>—free delivery and up to $40 in discounts. This plan is perfect
				for their large family and frequent social gatherings.
			</p>
			<p>
				<em>
					If you're spending around $20 weekly, you might consider upgrading to the "Save $5" plan. For weekly spending around $40, the "Save $10"
					plan is more beneficial. If you spend around $80 a week, the "Save $20" plan is ideal. And for the big spenders, the "Save $40" or even the
					"Save $100" memberships will give you the most bang for your buck!
				</em>
			</p>

			<h3>The Community-Centric Revolution: Flipping the Script</h3>
			<p>
				Here’s where GoBig pulls a total plot twist: Instead of hoarding profits, GoBig pours them back into the community. Imagine a world where
				restaurants decide how much commission they want to offer, and that commission gets turned into diner discounts. It’s like giving restaurants
				the keys to their own kingdom. This model ensures that both restaurants and diners thrive—restaurants control their finances, and diners enjoy
				hefty savings. Talk about a win-win!
			</p>

			<h3>Future-Proofing Our Communities: The Golden Age of Connection</h3>
			<p>
				What makes GoBig truly unique is its focus on the human emotional connection. By prioritizing intentions and community reinvestment, it
				transforms how we perceive transactions. It’s not just about exchanging money; it’s about building relationships and fostering trust. This new
				model positions itself as the currency of the golden age of our future, where technology is a tool for connection rather than division.
			</p>
			<p>
				In essence, GoBig is more than just an app—it’s a movement. A movement towards a future where community, convenience, and connection reign
				supreme. So, next time you’re deciding which food delivery service to use, remember that with GoBig, you’re not just saving money—you’re
				investing in a brighter, more connected future. And that’s something we can all get behind.
			</p>
		</article>
	);
}
