import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { DrawerProvider } from "common/contexts/DrawerContext";
import Sticky from "react-stickynode";
import { foodDeliveryTheme } from "common/theme/foodDelivery";
import { ResetCSS } from "common/assets/css/style";
import { GlobalStyle, ContentWrapper } from "../../containers/FoodDelivery/foodDelivery.style";

import Navbar from "../../containers/FoodDelivery/Navbar";
import Footer from "../../containers/FoodDelivery/Footer";
import Seo from "../../components/seo";
import BlogDetails from "../../containers/FoodDelivery/BlogDetails";

const FoodDelivery = () => {
	return (
		<ThemeProvider theme={foodDeliveryTheme}>
			<Fragment>
				<Seo title="Food Delivery" />
				<ResetCSS />
				<GlobalStyle />
				<ContentWrapper>
					<Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
						<DrawerProvider>
							<Navbar />
						</DrawerProvider>
					</Sticky>
					<BlogDetails />
					<Footer />
				</ContentWrapper>
			</Fragment>
		</ThemeProvider>
	);
};
export default FoodDelivery;
